<template>
  <div>
    <div class="warp-phone-bg">
      <div class="warp-phone-box">
        <div class="box-header">
          <div class="box-title">手机登录</div>

          <back-arrow></back-arrow>
        </div>
        <div class="phone-box">
          <div class="box-content-login">
            <van-field
              class="login-num"
              placeholder="请输入手机号"
              left-icon="phone-circle-o"
              v-model="phone.num"
              type="number"
            ></van-field>
          </div>
          <div class="box-content-code">
            <van-field
              class="login-code"
              v-model="phone.code"
              left-icon="comment-o"
              clearable
              placeholder="请输入验证码"
            >
              <template #button>
                <div class="push-code" @click="pushCode" v-if="!isCode">
                  发送验证码
                </div>
                <div class="push-code" v-else>{{ countdown }} 秒后重新发送</div>
              </template>
            </van-field>
          </div>
        </div>
        <div class="rule">
          <div class="rule-content">
            <van-checkbox
              shape="square"
              v-model="phoneCheck"
              icon-size="16"
              class="rule-check"
            ></van-checkbox>
            <div class="rule-agreement">
              <span>我已阅读并同意</span>
              <span @click="phoneUserProcotol">用户协议</span>
              <span>和</span>
              <span @click="phonePrivacyPolicy">隐私政策</span>
            </div>
          </div>
        </div>
        <div class="box-content-code-login">
          <button @click="userCodeLogin" class="box-content-code-login-btn">
            <span class="box-content-span">快速游戏</span>
          </button>
        </div>
        <div class="box-content-other-login">
          <button @click="userOtherLogin" class="box-content-other-login-btn">
            <span class="box-content-span"
              >其他登录方式 <van-icon name="arrow" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { phoneVerification, phoneLogin } from '@/service/moudules/wrapLogin';
import { getLocalStorage } from '@/util/userLoginFn';
import backArrow from './components/backArrow.vue';
import { isRegister } from '@/util/certification';
import { checkInviteCode } from '@/service/moudules/platformApi';
import { Toast } from 'vant';
export default {
  components: {
    backArrow,
  },
  data() {
    return {
      phoneVerification,
      getLocalStorage,
      isRegister,
      checkInviteCode,
      phone: {
        num: '',
        code: '',
      },
      phoneCheck: false,
      countdown: 60,
      isCode: false,
      inviteCode: '',
    };
  },
  methods: {
    phoneUserProcotol() {
      console.log(this.$route, 'route');
      this.$router.push({
        path: '/userProcotol',
      });
    },
    phonePrivacyPolicy() {
      this.$router.push({
        path: '/privacyPolicy',
      });
    },
    async userCodeLogin() {
      try {
        if (this.phone.num == '' || this.phone.code == '') {
          Toast.fail('请先填写手机号以及短信验证码');
          return false;
        } else {
          let reg = new RegExp(/^(?:(?:\+|00)86)?1\d{10}$/);
          let isReg = reg.exec(this.phone.num);
          isReg === null ? Toast.fail('请输入正确的手机号码格式') : null;
        }
        if (!this.phoneCheck) {
          Toast.fail('请先阅读并同意用户协议');
          return false;
        }
        let iParams = {
          inviteCode: this.inviteCode,
        };

        let res = await checkInviteCode(iParams);

        if (res.msg === 'false') {
          Toast.fail('邀请码有误,请检查~');
          this.setLocalStorage('inviteCode', '');
          return false;
        }

        let params = {
          phone: this.phone.num,
          code: this.phone.code,
          inviteCode: this.inviteCode,
          gameId: getLocalStorage('gameInfo').gameId,
          deviceId: window.localStorage.getItem('browserId')
        };

        let { data, msg, code } = await phoneLogin(params);

        if (code !== 200) {
          Toast.fail(msg);
          return false;
        }

        this.isRegister(data, msg);
      } catch (error) {
        console.log(error);
      }
    },
    userOtherLogin() {
      this.$router.push({
        path: `/wrapLogin?inviteCode=${this.getLocalStorage('inviteCode')}`,
      });
    },
    async pushCode() {
      try {
        if (this.phone.num === '') {
          Toast.fail('请填写手机号');
          return false;
        }
        this.isCode = true;
        let timer = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            this.countdown = 60;
            this.isCode = false;
            clearInterval(timer);
          }
        }, 1000);
        let params = {
          phone: this.phone.num,
        };

        let { data, code, msg } = await phoneVerification(params);
        if (code !== 200 || data === '') {
          Toast.fail(msg);
          return false;
        }

        Toast.success('验证码获取成功,请查收');
      } catch (error) {}
    },
  },
  created() {
    this.inviteCode = this.getLocalStorage('inviteCode');
  },
};
</script>
<style lang="less" scoped>
.warp-phone-bg {
  height: 100vh;
  background-image: url('../../assets/image/blogin-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  .warp-phone-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    width: 345px;
    height: 380px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.2);
    border-radius: 11px;
    // border: 2px solid;
    // border-image: linear-gradient(
    //     224deg,
    //     rgba(255, 255, 255, 0.5),
    //     rgba(255, 255, 255, 0.33),
    //     rgba(255, 255, 255, 0.08)
    //   )
    //   2 2;
    //backdrop-filter: blur(6px);
    .box-header {
      display: flex;
      justify-content: center;
      align-items: center;
      .box-title {
        position: relative;
        font-weight: 700;
        font-size: 18px;
        margin-top: 20px;
      }
      .el-icon-question {
        position: absolute;
        top: 23px;
        right: 20px;
        font-size: 18px;
        color: #000;
      }
    }
    .phone-box {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
      .box-content-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #e5e7ed;
        border-radius: 6px;
        width: 294px;

        .login-num {
          overflow-wrap: break-word;
          color: #000;
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
        }
      }
      .box-content-code {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #e5e7ed;
        border-radius: 6px;
        width: 294px;
        margin: 20px 0 0 4px;
        .login-code {
          overflow-wrap: break-word;
          color: #000;
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
          .push-code {
            color: #7351e9;
          }
        }
      }
    }
    .rule {
      margin-top: 20px;
      .rule-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .rule-check {
          margin-right: 10px;
        }
        .rule-agreement {
          color: #000;
          font-size: 14px;
          font-size: 14px;
          font-family: PingFang-SC-Medium;
          & :nth-child(2) {
            color: #815fe7;
            font-size: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
          }
          & :nth-child(4) {
            color: #815fe7;
            font-size: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
          }
        }

        .policy {
        }
      }
    }
    .box-content-code-login {
      display: flex;
      justify-content: center;
      align-items: center;
      .box-content-code-login-btn {
        border-radius: 23px;
        border: 1px solid;
        border-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
        background-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
        margin-top: 24px;
        padding: 9px 110px 8px 110px;
        .box-content-span {
          overflow-wrap: break-word;
          color: #fff;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          font-weight: 500;

          white-space: nowrap;
          line-height: 25px;
        }
      }
    }
    .box-content-other-login {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      .box-content-other-login-btn {
        border-radius: 23px;
        border: 2px solid #5266c9;
        margin-top: 24px;
        padding: 9px 80px 8px 80px;
        .box-content-span {
          overflow-wrap: break-word;
          color: #7351e9;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          white-space: nowrap;
          line-height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
